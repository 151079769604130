@media (max-width: 640px) {
  .card {
    max-width: 100%;
    width: 100vw;
    margin-left: 15px;
  }
}

@media (max-width: 320px) {
  .card {
    max-width: 100%;
    width: 100vw;
    padding: 0;
    margin-left: 15px;
  }
}

.card {
  max-height: 460px;
}

.card:hover {
  transform: scale(1.02);
  cursor: pointer;
  transition: ease-in-out 0.3s;
}
